.tableExpended {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;

    span {
        padding: 0.2rem 2rem;
        border: 1px solid #cdcdcd;
        border-radius: 0.3rem;
        margin: 0 0.3rem;
    }
}

.contFactModal {
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
}

.A4 {
    min-height: 1000px;
    padding: 1rem;
    margin: 0;
    // background-color: #ABCDEF;
    position: relative;

    .inA4 {
        padding: 2rem;

        .header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 3rem;

            .title {
                padding-top: 5rem;
                width: 55%;
            }
        }

        .dueDate {
            width: 100%;
            text-align: center;
            margin-bottom: 3rem;
        }

        .factDetail {
            margin-bottom: 4rem;
        }

        .headerOneProd {
            margin-top: 1rem;
            display: flex;
            justify-content: space-between;
            padding: 0.5rem;
            border-bottom: 1px solid #cdcdcd;
        }

        .footerOneProd {
            display: flex;
            justify-content: space-between;
            padding: 0.5rem;
            margin-bottom: 1rem;

        }

        .products {


            .oneProduct {
                display: flex;
                justify-content: space-between;
                padding: 0.5rem;
                border-bottom: 1px solid #cdcdcd;
            }
        }

        .message {
            padding: 1rem;
            text-align: left;
        }

        .qrcode {
            padding-left: 1rem;
            // text-align: center;
            // width: 160px;
        }

        .iban {
            width: 60%;
            text-align: left;
        }

        .recipient {
            position: absolute;
            font-size: 1.2em;
            top: 50mm;
            height: 45mm;
            right: 0;
            width: 60%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            // border:1px solid #cdcdaa;
            div {
                padding-left: 10mm;
            }

        }

        .printFooter {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: center;
            height: 182px;
            bottom: 1rem;
            left: 0;
            text-align: center;
            width: 100%;

        }

    }

}


.page-break {
    display: none;
}

@media print {

    html,
    body {
        height: 100%;
        /* Use 100% here to support printing more than a single page*/
        margin: 0 !important;
        padding: 0 !important;
        //   overflow: hidden;
    }

    .A4 {
        height: 100vh;
        padding: 0;
        background-color: #fff;

        .header {
            margin-bottom: 2cm !important;
        }
    }

    .page-break {
        margin-top: 0;
        display: block;
        page-break-before: auto;
    }

    .printFooter {
        bottom: 10mm !important;
        width: 210mm !important;
    }

    .recipient {
        width: 100mm !important;
        height: 45mm !important;
        right: 10mm !important;
        // background-color: #cdcdcd;
    }


}

.homeFacturation {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-evenly;

    .futureSup {
        min-width: 450px;
        max-width: 500px;
        margin: 1rem auto;
    }
    .unpaid{
        min-width: 500px;
        width: 100%;
    }
}

@page {
    size: auto;
    margin: 0;
}

@import 'facturationDefaultPage';
.annualRecap {
    .noResult{
        width:100%;
        height: 300px;
        text-align: center;
        font-size: 2rem;
        color:#cdcdcd;
        padding-top:2rem;

    }
    margin-top: 3rem;
    margin-bottom: 100vh;
    .recapContainer{
        margin-bottom: 3rem;
    }
    .styckyDate {
        position: sticky;
        top: 60px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.81);
        border: 1px solid #e3e3e3 !important;

        .lineCat {
            border: 0px solid transparent !important;
            // border-bottom: 1px solid #e3e3e3 !important;
        }

        .oneMonthCat {
            padding: 0.5rem 0 !important;

            h3 {
                font-size: 1rem;
            }
        }
    }

    .parent {

        h1,
        h2,
        h3 {
            margin: 0;
            padding: 0;
        }

        h1 {
            font-size: 1.275rem;
            padding: 0.5rem 0;
            margin-top: 32px;
        }

        h2 {
            font-size: 1em;
            font-weight: 400;
        }
    }

    .lineCat {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        border-left: 1px solid rgb(231, 235, 238);
        border-right: 1px solid rgb(231, 235, 238);
        border-bottom: 1px solid rgb(231, 235, 238);

        &:first-of-type {
            border-top: 1px solid rgb(231, 235, 238);
        }
    }

    .catTitle {
        width: 15vw;
        padding-left: 0.5rem;
    }



    .ant-select-item-option-active {
        background-color: rgb(159, 139, 224) !important;
    }

    .ant-select-item-option-selected, .ant-select-item-option {
        background-color: rgb(137, 109, 226) !important;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
        background-color: green !important;

    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: rgba(71, 31, 219, 0.54) !important;
    }
 
    // .ant-select-selector {
    //     background-color: green;
    //   }
    :where(.css-dev-only-do-not-override).ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: rgba(71, 31, 219, 0.44);
    }

    .monthLineCat {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(13, 1fr);
        grid-column-gap: 0;

        .AnualCumul {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 0.7rem;
            color: rgb(97,1,238);

            h3 {
                display: block;
                text-align: center;
                margin: 0 auto;
                font-size: 0.8em;
                padding: 0;
            }
        }

        .oneMonthCat {
            display: flex;
            font-size: 0.9em;
            align-content: center;
            align-items: center;
            justify-content: flex-end;
            padding: 0.7rem;
            border-left: 1px solid rgb(231, 235, 238);
            // font-weight: 700;

            .oeil {
                display: flex;
                justify-content: space-between;
                align-content: center;
                flex-wrap: wrap;
                align-items: center;
                width: 100%;
            }



            h3 {
                display: block;
                text-align: center;
                margin: 0 auto;
                font-size: 0.8em;
                padding: 0;
            }

            &.impair {
                background-color: rgb(250, 250, 250);
            }

            &.pair {
                // background-color: #f1f1f1;
                background-color: rgb(254, 254, 254);
            }
        }
    }
}
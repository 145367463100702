.categories {
    .categFlex {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .oneCat{
            border:1px solid #cdcdcd;
            border-radius: 0.5rem;;
            width: 380px;
            margin:0.4rem 0.4rem 2rem 0.4rem;
            padding:0.5rem;

            .cat{
                display: flex;
                justify-content: space-between;
                padding: 0 0 0.5rem 0;
            }
            .subCat{
                display: flex;
                justify-content: space-between;
                padding:0.3rem 0.5rem;
            }
            .editTitle{
                width: 280px;
                display: inline-block;
            }
        }
        

    }
}
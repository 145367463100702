@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');


:root {
  --first-color: #6200ee;
  --first-color-hover: #ba97eb;
  --second-color: #0ac99e; //#0CC99D;
  --background-color: #fefefe;
  --background-graph: rgba(255, 255, 255, 0.9);
  --small-color: #2A2A2A;
  --h2-color: #bd1621;
  --text-color: #3f3f3f;
  --transition: 0.5s ease-in;
  --headHeight: 7.5rem;
  --box_shadow: rgba(0, 0, 0, .2) 0 5px 5px -3px, rgba(0, 0, 0, .14) 0 8px 10px 1px, rgba(0, 0, 0, .12) 0 3px 14px 2px;
  --error-color: #ff1493;
  --error-color-hover: #f89ccd;

}

.dark-mode {
  --small-color: #363535;
  --background-color: #eaeaea;
  --h2-color: rgb(131, 130, 130);
  --background-graph: rgba(208, 208, 208, 0.9);
  --text-color: #3c3c3c;
  --transition: 0.3s ease-out;

}

.hButton {
  margin-left: 1rem;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

html {
  font-family: 'Inter', sans-serif;
  font-weight: 500;


}

.App {
  text-align: center;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.slctbt {
  width: 150px;
  text-align: center;
  padding: 0.2rem 1rem;
  margin: 0.2rem auto;
}

.oneday {
  margin-bottom: 1rem;
  padding: var(--headHeight) 1rem 0;
  min-height: 50vh;

}

.graphSup {
  width: 33vw;
  height: 25wh;
  position: fixed;
  top: 0;
  right: 0;
  overflow: visible;
  background-color: var(--background-graph);
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  border-radius: 5px;
  margin-right: 1.5rem;
}

.loading {
  margin: 2rem 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;

  .ant-spin-dot-item {
    background-color: var(--error-color);
  }

  .ant-spin-text {
    color: var(--error-color)
  }

  &.loadingCover {
    margin: 0 !important;
    width: 100%;
    height: 100vh;
    position: fixed;
    padding-top: 45vh;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 100000;


  }
}



.arrow {
  height: 18px;
  width: auto;
  stroke: 1px;

  &.up {
    fill: green;
  }

  &.down {
    fill: red;
    transform: rotate(-180deg);
  }
}

small {
  font-weight: bold;
  color: var(--small-color);
}

.tbl {
  width: 100%;
  margin: 0;
  padding: 0;
}

.transac .sup {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: space-evenly;
  justify-content: center;
  border: 1px solid #cdcdcd;
  background-color: #efefef;
}

.transac .sup .item {
  padding: 0.1rem 0.5rem;

}

.flx {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: space-evenly;
  justify-content: space-between;
}

.flx2 {
  margin-bottom: 0.3rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  align-content: space-evenly;
  justify-content: center;

}

.title {
  width: 150px;
}

.red {
  color: red;
}

.green {
  color: green;
}

.transac {
  width: 100%;
}

.transacFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;

  .item {
    flex: 1 1 80px;
    padding: 0.1rem 0.1rem;
    text-align: center;
  }
}


.cumul {
  position: fixed;
  bottom: 8rem;
  left: 1rem;
  // width: 100%;
  z-Index: 1000;
  overflow: hidden;
  display: flex;
  text-align: center;
  overflow: visible;
  height: 0;

  .badge {
    background-color: var(--background-graph);
    margin: 0.2rem auto;
    padding: 1rem;
    height: 5rem;

    border: 1px solid transparent;
    border-radius: 5px;
    display: flex;
    min-width: 450px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;


    .left,
    .right {
      flex: 1 1 auto;
    }


  }
}

.supTable {
  transition: 10.3s;

  &.visible {
    // display: block;
    opacity: 1;
    transition: all 0.3s;

  }

  &.hidden {
    // display: none;
    opacity: 0;
    height: 0;
    transition: all 0.3s;

  }
}

.btn {
  align-items: center;
  appearance: none;
  border-radius: 4px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 1px -2px, rgba(0, 0, 0, .14) 0 2px 2px 0, rgba(0, 0, 0, .12) 0 1px 5px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: .875rem;
  font-weight: 500;
  height: 36px;
  justify-content: center;
  letter-spacing: .0892857em;
  line-height: normal;
  min-width: 64px;
  outline: none;
  overflow: visible;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  will-change: transform, opacity;
  transition: all 0.3s;


  &:hover {
    box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 4px 5px 0, rgba(0, 0, 0, .12) 0 1px 10px 0;
    transform: scale(1.02);
    background-color: #5802d2;
  }

  &:disabled {
    background-color: rgba(0, 0, 0, .12);
    box-shadow: rgba(0, 0, 0, .2) 0 0 0 0, rgba(0, 0, 0, .14) 0 0 0 0, rgba(0, 0, 0, .12) 0 0 0 0;
    color: rgba(0, 0, 0, .37);
    cursor: default;
    pointer-events: none;
    transform: scale(0.98);
  }

  &:not(:disabled) {
    background-color: var(--first-color);
  }

  &:focus {
    box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 4px 5px 0, rgba(0, 0, 0, .12) 0 1px 10px 0;
  }

  &:active {
    box-shadow: rgba(0, 0, 0, .2) 0 5px 5px -3px, rgba(0, 0, 0, .14) 0 8px 10px 1px, rgba(0, 0, 0, .12) 0 3px 14px 2px;
    background: #A46BF5;
  }

}

.divCode {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  align-items: stretch;

  .pagename {
    color: #b20808;
    font-style: italic;
    text-align: left;
    font-size: 0.9rem;
    margin: 1rem auto 0;
    padding: 0 1rem;
    display: block;
    width: 100%;
  }

  .dev {
    font-size: 0.9em;
    background-color: #e6f4ff;
    border: 1px solid #dfdfff;
    border-radius: 5px;
    padding: 1rem;
    color: #1d447a;

  }

}

pre {
  font-size: 0.7em;
  padding: .5rem;
  margin: 0.3rem;
  border: 1px solid #cdcdcd;
  background-color: #efefef;
  max-height: 33vh;
  min-height: 200px;
  min-width: 300px;
  max-width: 48vw;
  overflow: scroll;
}

.notifieur {
  .notif {
    z-index: 10000;
    width: 400px;
    min-height: 100px;
    background-color: white;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 4px 5px 0, rgba(0, 0, 0, .12) 0 1px 10px 0;
    position: fixed;
    bottom: 10px;
    right: -10x;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    &.alert {
      color: rgb(255, 183, 0);
    }

    &.error {
      color: rgb(255, 38, 0);
    }

    &.success {
      color: rgb(4, 148, 119);
    }
  }
}

.ant-btn-primary {
  background-color: var(--first-color);

  &:not(:disabled):hover {
    color: #fff;
    background-color: #A46BF5;
  }
}

.ant-btn-default.ant-btn-dangerous {
  border-color: var(--error-color) !important;
}

.ant-btn-default.ant-btn-dangerous:not(:disabled):hover {
  border-color: var(--error-color-hover) !important;

}

.ant-switch:hover:not(.ant-switch-disabled) {
  background: rgba(191, 9, 9, 0.45);
}

.ant-switch-checked {
  background-color: #0ac99e !important;
  background: #0ac99e !important;
}

.ant-switch {
  background-color: #6200ee;
  background: #6200ee;
}

.Mui-checked,
.MuiCheckbox-indeterminate {
  color: var(--first-color) !important;
}


// Schedule Task OffLine OnLine

.pendingTask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 3rem;
  z-index: 2000000;

}
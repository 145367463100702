nav,
.navFactu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    padding: 0.3rem;


    .homebt {
        svg {
            width: 36px;
            height: 36px;
            margin-left: 1rem;
            cursor: pointer;

            .target {
                fill: #71c2ff;
                transition: 0.3s ease-in;
            }

            .target2 {
                fill: #fff;
                transition: 0.3s ease-out;
            }
        }

        &:hover {
            svg {
                .target {
                    fill: #08365a;
                }
            }
        }

        &.active {
            svg {
                .target2 {
                    fill: #6200ee;
                }
            }
        }
    }



    .nbutton {
        margin: 0 2rem;
        background-color: transparent;
        border: none;
        padding: 10px;
        cursor: pointer;
        transition: 0.3s linear;
        outline: none;
        border-radius: 5px;



        &::after {
            content: "";
            display: block;
            height: 2px;
            width: 0;
            background: #6200ee;
            transition: width 0.3s ease-in;
        }

        &:hover::after {
            width: 100%;
        }

        span {
            padding: 0 5px;
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 1rem;
            margin: auto;

        }

        &.active {
            color: #6200ee;

            &::after {
                content: "";
                display: block;
                height: 2px;
                width: 100%;
                background: #6200ee;
                transition: width 0.3s ease-in;
            }

        }
    }

}

.navFactu {
    position: sticky;
    top: 48px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    z-index: 910;
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    z-index: 900;
}

.user {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    min-width: 200px;
    margin: 0 1rem;

    .logout {
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        // background-color: rgba(100,100,120,0.6);
        color: white;
        font-size: 0.675rem;
        font-weight: 500;
        height: 32px;
        justify-content: center;
        letter-spacing: 0.0692857em;
        line-height: normal;
        min-width: 50px;

        &:hover {
            background-color: rgba(154, 10, 165, 0.6);

        }
    }
}
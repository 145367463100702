.loginPage {
    min-height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;

    .piggyTitle{
        font-size: 2.5rem;
        line-height: 2.5rem;
        color:rgb(128, 68, 68);
        margin: 1rem auto;


    }

    .supSvg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;

        svg {
            min-width: 100vw;

        }
    }

    .inputsup {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        width: 100%;
        max-width: 400px;
        height: 80px;
        position: relative;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        margin-bottom: 10px;
        background-color: rgba(255, 255, 255, 0.6);


        input {
            outline: none;
            border: none;
        }


        input:focus::-webkit-input-placeholder {
            color: transparent;
        }

        input:focus:-moz-placeholder {
            color: transparent;
        }

        input:focus::-moz-placeholder {
            color: transparent;
        }

        input:focus:-ms-input-placeholder {
            color: transparent;
        }


        input::-webkit-input-placeholder {
            color: #999999;
        }

        input:-moz-placeholder {
            color: #999999;
        }

        input::-moz-placeholder {
            color: #999999;
        }

        input:-ms-input-placeholder {
            color: #999999;
        }




        label {
            text-align: left;
            display: block;
            margin: 0;
            font-size: 18px;
            color: #999999;
            line-height: 1.2;
            position: absolute;
            pointer-events: none;
            width: 100%;
            padding-left: 24px;
            left: 0;
            top: 30px;
            -webkit-transition: all 0.4s;
            -o-transition: all 0.4s;
            -moz-transition: all 0.4s;
            transition: all 0.4s;

            &.blur {
                top: 12px;
                font-size: 13px;
            }
        }

        /*---------------------------------------------*/


        input {
            display: block;
            width: 100%;
            height: 100%;
            background: transparent;
            font-size: 18px;
            color: #555555;
            line-height: 1.2;
            padding: 0 26px;
        }

    }

    button {
        outline: none !important;
        border: none;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        width: 100%;
        max-width: 400px;
        height: 50px;
        border-radius: 10px;
        background: rgb(255, 170, 170);
        font-size: 12px;
        color: #fff;
        line-height: 1.2;
        text-transform: uppercase;
        letter-spacing: 1px;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;

        &:hover {
            cursor: pointer;
        }
    }


    .error {
        min-height: 3rem;
        max-width: 400px;
        font-weight: bold;
        color: #ff002dc2;
    }

    .login {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.6);
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        max-width: 33vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: stretch;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

    }
}
.loggedPage {
    margin-top: 2.8rem;

    .page {
        padding: 0.5rem 1rem;
    }

    .btAddExpense {
        position: absolute;
        top: 21px;
    }

    .icoPro {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
        color: var(--first-color);
        font-size: 1.1rem;

        span {
            margin-left: 0.1rem;
        }

        code {
            font-size: 0.65rem;
            padding: 0.2rem;
            // background-color: #cdcdcd;
            color: #444;
        }
    }

    .positif {
        .price {
            color: var(--second-color);
        }
    }

    .negatif {
        .price {
            color: var(--first-color)
        }
    }
}

.precodes {
    display: flex;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;

    .flexPre {
        display: flex;
    }

}

.categorie {
    display: flex;
    flex-direction: column;
    width: 98%;
    max-width: 400px;
    min-height: 10rem;
    // padding: 1rem;

    .selectMenu {
        width: 98%;
        margin-bottom: 1rem;
    }
}

.lieu,
.dateSelect,
.montant {
    width: 98%;
    max-width: 400px;

    // padding: 1rem;
    .error {
        color: var(--error-color);
    }
}



.spendInputs {
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
}

.montantInput,
.reccuInput {
    input {
        padding: 0.445rem;
        border-radius: 4px;
    }

    .ant-select-selector,
    .ant-input-group-addon {
        border-radius: 4px;
    }
}

.btResponses {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;

    .item {
        min-height: 100px;
    }

    .error,
    .valid {
        max-width: 400px;
        padding-top: 0rem;
        font-size: 1em;

        .dev {
            font-size: 0.8rem;
            color: darkolivegreen;
            text-align: left;
            padding: 0.2rem 1rem;
        }

    }
}

.switchDepense {
    display: flex;
    align-items: center;
    justify-content: center;

    .supSwitch {
        display: flex;
        align-items: center;
        margin: 0.5rem;

        .swtitle {
            padding-right: 0.1rem;
            font-size: 0.9rem;
        }

        // .ant-switch-checked {
        //     background-color: #6200ee !important;
        // }
    }

}

// select date
.selectDate2 {
    transition: all 3.33 ease-in-out;

    &.d2visible {

        height: 0;
        opacity: 0;
        transition: all 3.33 ease-in-out;
    }

    &.d2hidden {
        height: 100%;
        opacity: 1;
        transition: all 3.33 ease-in-out;

    }
}

.infoTopContainer {
    display: flex;

    .noResult {
        width: 100%;
        text-align: center;
        font-size: 1.2rem;
        color: #cdcdcd;
        padding-top: 2rem;

    }

    .content {
        width: 48vw;

        h1 {
            font-size: 1.275rem;
            padding: 0.5rem 0;
        }

        h4 {
            margin-bottom: 0.3rem;
            color: var(--first-color);

            &.positif {
                color: var(--second-color);
            }
        }
    }

}

.Actual {
    // max-width: 400px;
    padding: 0.3rem;

    .titleH1Select {
        display: grid;
        grid-template-columns: 1.5fr 1fr;

        h1 {
            margin: 0;
        }

        .contSelect {
            text-align: right;
            padding: 0.5rem;
        }
    }

    // .Cumul{
    //     h1{
    //         margin:0 0 0.3rem 0;
    //     }
    // }

    .bbtSup {
        color: #aaa;

        .sortie {
            cursor: pointer;
            color: var(--first-color);

            &:hover {
                color: var(--first-color-hover);
            }

            &.activ {
                text-decoration: underline;
                font-weight: bold;
            }

        }

        .entree {
            color: var(--second-color);
            cursor: pointer;

            &:hover {
                color: #86c7b8;
            }

            &.activ {
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }

    .title {
        text-align: left;
        width: 100%;
    }

    .price {
        text-align: right;
        color: var(--first-color);

        &.positif {
            color: var(--second-color)
        }
    }

    .catParentLine {
        // padding-bottom: .1rem;

        .titleCont {
            display: grid;
            grid-template-columns: 1.5fr 1fr;
            border-bottom: 1px solid rgb(231, 235, 238);
            padding: 0.5rem 0.5rem 0 0.5rem;
            background-color: #fff;

            div {
                padding: 0.5rem;

                &.price {
                    padding-right: 0;
                    font-weight: bold;
                    // color: #ba97eb;
                }
            }
        }
    }

    .totalAmountCont {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
        gap: 0px 0px;
        padding: 0.4rem;
        align-items: center;

        .ant-switch-checked {
            background-color: var(--second-color) !important;
            background: var(--second-color) !important;
        }

        .ant-switch {
            background-color: var(--first-color);
            background: var(--first-color);
        }

        h1 {
            margin: 0;
        }

        h5 {
            margin: 0 0 0.3rem 0;
        }

        .price {
            font-weight: bold;

            small {
                color: var(--first-color-hover);
            }

            &.in {
                color: var(--second-color) !important;

                small {
                    color: #86c7b8 !important;
                }
            }

            &.negatif {
                color: #ee3000;

                small {
                    color: #ea7b60;
                }
            }

            &.positif {
                color: var(--second-color) !important;

                small {
                    color: #86c7b8 !important;
                }
            }
        }

    }

    .contByCat {
        height: 40vh;
        overflow-y: auto;
        border: 1px solid rgb(231, 235, 238);
        background-color: rgb(253, 253, 253);
    }

    .subCatLine {
        display: grid;
        grid-template-columns: 2.5fr 1fr;
        gap: 0px 0px;
        padding: 0.4rem;
        align-items: center;
        border-bottom: 1px solid rgb(231, 235, 238);

        &:nth-child(even) {
            background-color: rgb(250, 250, 250);
        }

        &:nth-child(odd) {
            background-color: rgb(254, 254, 254);
        }

        .title {
            padding-left: 1rem;
            width: 100%;
        }


    }

}

.reccuLine {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1.5fr 1fr;
    gap: 0px 0px;
    border-left: 1px solid rgb(231, 235, 238);
    border-right: 1px solid rgb(231, 235, 238);
    border-bottom: 1px solid rgb(231, 235, 238);

    &:first-of-type {
        border-top: 1px solid rgb(231, 235, 238);
    }

    &:nth-child(even) {
        background-color: rgb(250, 250, 250);
    }

    &:nth-child(odd) {
        background-color: rgb(254, 254, 254);
    }



    div {
        padding: 0.5rem;
    }

    .date {
        text-align: right;
    }

    .text {
        text-align: left;
    }

    .price {
        text-align: right;
        color: var(--first-color);

        &.positif {
            color: var(--second-color);
        }
    }

}

.ant-select-selection-item {
    color: var(--first-color);
    font-weight: bold !important;
}

.supReccuResum,
.supUnpaid {
    border-left: 1px solid rgb(231, 235, 238);
    border-right: 1px solid rgb(231, 235, 238);
    border-top: 1px solid rgb(231, 235, 238);

    .rowReccuExpenses,
    .rowUnpaid {
        &.header {
            font-weight: bold;
        }

        display: flex;
        // max-width: 90%;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        padding: 0.4rem;
        border-bottom: 1px solid rgb(231, 235, 238);

        &:nth-child(even) {
            background-color: rgb(250, 250, 250);
        }

        &:nth-child(odd) {
            background-color: rgb(254, 254, 254);
        }

        .price {
            color: var(--first-color);
            min-width: 130px;
            text-align: right;
        }
    }

    .rowUnpaid {
        display: grid;
        // grid-auto-columns: ; 
        grid-template-columns: 1fr 1fr 2.5fr 1fr;
        gap: 0 2px;
        row-gap: 0px;
        column-gap: 2px;
        justify-items: center;
        align-content: center;
        justify-content: center;
        align-items: center;

        .date {
            font-size: 0.9rem;
        }
    }
}

.supUnpaid {
    max-height: 70vh;
    overflow-y: auto;
}

.btReccuSup {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.onlineStatue {
    width: 180px;
    background-color: #FFF;
    border:1px solid #cdcdcd;
    padding:3px;
    border-radius:5px;
    margin:1rem;

    // &.online {
    //     // width: 100%;
    // }

    &.offline {
        position: fixed;
        bottom: 0;
        left: 0;
    }
}
.futureBill {
    // max-width: 400px;
    margin: 0 auto;

    .oneBill {
        border-bottom: 1px solid #cdcdcd;
        margin-bottom: 2rem;

        .ant-checkbox-wrapper{
            min-width:140px !important;
        }

        .defaultResumeValue {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom:0.5rem;

        }
    }
}
.creaError{
    text-align: center;
    color:var(--error-color);
    padding:1rem;
}

.Customers{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
    
    .oneCustomer{
        width: 100%;
        max-width:400px;
        padding:1rem;
        border:1px solid #cdcdcd;
        margin:0 1rem 2rem 0;
        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .resumeValue {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom:0.5rem;

        }
        .subHeader{
            min-height:4rem;
        }

        small{
            font-weight: 300;
        }
    }
}